import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import withContext from '../../ContextAPI/Context_HOC';
import FundHeart from '../../assets/img/FundHeart.png';

class FundResources extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col className="mx-auto">
            <img src={FundHeart} alt="Baha’i Fund" className="fund-logo" />
          </Col>
          <Col xs={12} lg={10} className="mx-auto">
            <h1>September 5, 2024 Town Hall Webinar Recording</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6} className="mx-auto">
            <a href="https://bahaitreasurer.us/" target="_blank" rel="noreferrer">
              https://bahaitreasurer.us/
            </a>
            <iframe
              src="https://player.vimeo.com/video/1012191607?h=2089eeef16"
              width="100%"
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            ></iframe>
            <p>
              To stay connected to the Fund, text 224-414-FUND (3863) and in the message box write &ldquo;Bahaifund&ldquo;. Have
              questions? After signing up, Text 224-414-FUND (3863), and in the message box, write your question.
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withContext(FundResources);
